import * as React from "react";
import {Container, Divider, Header, Image, Segment} from "semantic-ui-react";
import {Page} from "../../modules/page";
import {TearOfAshaVision} from "../../assets/perks/luck";

/**
 * Страница описания работы программы и сайта
 */
export const AboutAsha = () => {
    return (
        <Page>
            <Segment textAlign="left" style={{ fontSize: '18px' }}>
                <Image src={TearOfAshaVision} centered size="tiny" />
                <Container>
                    <Header as={'h2'} content="АСХА - Автоматическая Система для Хранения игр и Аналитики" />
                    <p>
                        АСХА предназначена для сбора информации об всех играх участников данного проекта с целью
                        регулирования баланса в подключенных к системе модификаций для игры Heroes Of Might and Magic 5.<br/>
                        Асха сохраняет информацию об играх игроков и представляет информацию в удобном формате.
                    </p>
                </Container>
                <Divider />
                <Container>
                    <Header as={'h2'} content="Какую информацию сохраняет АСХА?" />
                    <p>
                        <ol>
                            <li>Время игры и версия карты.</li>
                            <li>Герои и их характеристики.</li>
                            <li>Стартовая армия.</li>
                            <li>Конечная армия победителя.</li>
                            <li>Навыки и заклинания.</li>
                            <li>Артефакты.</li>
                        </ol>
                    </p>
                </Container>
                <Divider />
                <Container>
                    <Header as={'h2'} content="Что доступно в данный момент?" />
                    <p>
                        <ol>
                            <li>Просмотр подробностей игры (на момент турнира информация об игроках скрыта)</li>
                            <li>Просмотр общей статистики - в зачет идут игры между теми игроками, у которых установлена АСХА.</li>
                            <li>Просмотр индивидуальной статистики (на момент турнира информация об игроках скрыта)</li>
                            <li>Поиск игр по фильтрам</li>
                        </ol>
                    </p>
                </Container>
                <Divider />
                <Container>
                    <Header as={'h2'} content="Из чего состоит АСХА?" />
                    <p>
                        <ol>
                            <li>Виндовс клиент. Клиент собирает информацию и отправляет на сайт.</li>
                            <li>Сайт АСХИ. Хранит и отображает информацию, которую прислал клиент.</li>
                        </ol>
                    </p>
                </Container>
                <Divider />
                <Container>
                    <Header as={'h2'} content="Как начать пользоваться АСХОЙ?" />
                    <p>
                        <ol>
                            <li>Авторизоваться через Discord на сайте АСХИ: https://asha-heroes.ru/ (необходимо состоять в сообществе discord HRTA main).</li>
                            <li>Скачать последний клиент АСХИ. Актуальный клиент в ⁠asha.</li>
                            <li>После скачивания клиент необходимо распаковать в удобную для вас директорию и запустить установку через setup.exe. Установить можно куда угодно.</li>
                            <li>Файлы установщика после установки удалять и перемещать нельзя! Так что продумайте этот пункт заранее.</li>
                            <li>Запуск и авторизация в клиенте.</li>
                            <li>Запустите клиент. Клиент запросит токен авторизации.</li>
                            <li>Зайдите в свой профиль на сайте АСХА и скопируйте необходимый токен в клиент.</li>
                            <li>Удалите console.txt в корневой папке игры /bin. Сейчас это баг, будет исправлено.</li>
                            <li>Нажать на кнопку начать игру, чтобы выбрать экзешник. Он находится в папке /bin.</li>
                            <li>Играйте и следите за своими играми на сайте).</li>
                        </ol>
                    </p>
                </Container>
                <Divider />
                <Container>
                    <Header as={'h2'} content="Возможности клиента" />
                    <p>
                        <ol>
                            <li>Если вы одержали победу - клиент уведомит вас и даст ссылку на вашу игру на сайте</li>
                            <li>Клиент можно закрывать (он сворачивается в трей)</li>
                            <li>Чтобы включить/отключить автозагрузку вместе с windows нажмите "включить/отключить" в клиенте</li>
                        </ol>
                    </p>
                </Container>
                <Divider />
                <p>
                    <b>Этот проект снова существует благодаря этим людям, спасибо!</b><br/>
                    <b>Разработка: Syrill, Imladris, Queela, Ged_Blood, Persona, Geradot, Jester, IuriiSh</b><br/>
                    <b>Тестирование: Persona, IuriiSh, smoker-, Merlin, Ikiru Tavi, Waterel</b><br/><br/><br/>
                    <b>Этот проект существует благодаря этим людям, спасибо!</b><br/>
                    <b>Разработка: Mostovik, Sokratik, gRiboN</b><br/>
                    <b>Тестирование: Persona, Deinera, Valah Belyadi, Welsch, 3y6, Auragash, Ikiru Tavi, Moonitouch, RayFever</b>
                </p>
            </Segment>
        </Page>
    );
}
